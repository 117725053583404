<script setup lang="ts">
import VueDatePicker from "@vuepic/vue-datepicker";
import type { ModelValue } from "@vuepic/vue-datepicker";

withDefaults(
	defineProps<{
		modelValue: ModelValue;
		placeholder?: string;
		range?: boolean;
		autoApply?: boolean;
		locale?: string;
		utc?: boolean | "preserve";
		format?: string;
		maxDate?: Date | string;
		startDate?: Date | string;
		position?: "left" | "center" | "right";
		flow?: ("month" | "year" | "calendar" | "time" | "minutes" | "hours" | "seconds")[];
		teleportCenter?: boolean;
		preventMinMaxNavigation?: boolean;
	}>(),
	{
		modelValue: null,
		placeholder: "Enter Date",
		autoApply: false,
		locale: "en-US",
		format: "dd.MM.yyyy",
		utc: false,
		startDate: undefined,
		maxDate: undefined,
		position: "center",
		teleportCenter: false,
		preventMinMaxNavigation: true
	}
);

const emit = defineEmits<{
	(event: "update:modelValue", value: ModelValue): void;
}>();
const setValue = (newValue: ModelValue) => {
	emit("update:modelValue", newValue);
};
</script>
<template>
	<VueDatePicker
		:model-value="modelValue"
		:clearable="false"
		:enable-time-picker="false"
		:placeholder="placeholder"
		:range="range"
		:month-change-on-scroll="false"
		:action-row="{ showPreview: false }"
		:format="format"
		month-name-format="long"
		select-text="Ok"
		no-today
		hide-offset-dates
		:auto-apply="autoApply"
		:locale="locale"
		:utc="utc"
		:start-date="startDate"
		:max-date="maxDate"
		:position="position"
		:flow="flow"
		:prevent-min-max-navigation="preventMinMaxNavigation"
		:teleport-center="teleportCenter"
		@update:model-value="setValue"
	>
		<template #input-icon>
			<slot name="afterInput" />
		</template>
	</VueDatePicker>
</template>
<style>
@import url("@vuepic/vue-datepicker/dist/main.css");

:root {
	--m-datepicker-max-width: 304px;
	--m-datepicker-background-color: transparent;
	--m-datepicker-icon-color: #abb7e0;
	--m-datepicker-border-color: var(--chongjin, #ddd);
	--m-datepicker-input-color: var(--cannes);
	--m-datepicker-button-background-color: var(--chimoio);
	--m-datepicker-hover-color: var(--cuba);
	--m-datepicker-header-color: rgba(255, 255, 255, 0.5);
	--m-datepicker-disabled-color: rgba(255, 255, 255, 0.2);

	--dp-font-family: inherit;
	--dp-border-radius: 10px;
	--dp-input-padding: 8px 35px 8px 10px;
	--dp-input-icon-padding: 10px;
	--dp-font-size: 12px;
	--dp-menu-background: var(--cracow);
	--dp-menu-padding: 24px 12px 0;
	--dp-menu-min-width: 304px;
	--dp-month-year-row-height: 24px;
	--dp-button-icon-height: 24px;
	--dp-cell-size: 40px;
	--dp-row-maring: 0;
	--dp-cell-border-radius: 50%;
	--dp-scroll-bar-background: transparent !important;
}

.funzCity:root {
	--m-datepicker-background-color: var(--cannes);
	--m-datepicker-input-color: var(--comilla);
	--m-datepicker-border-color: var(--cuttack);
}
.nlc:root {
	--m-datepicker-button-background-color: var(--changchun);
	--m-datepicker-input-color: var(--carpio);
	--m-datepicker-background-color: var(--canosa);
	--m-datepicker-border-color: var(--canosa);
	--m-datepicker-hover-color: rgba(var(--changchun-rgb), 0.3);
	--dp-input-padding: 13px 16px;
	--dp-menu-background: var(--canosa);
	--m-datepicker-disabled-color: var(--overlay-light);
}
.fortuneWheelz:root {
	--m-datepicker-button-background-color: var(--caracas);
	--m-datepicker-input-color: var(--cesena);
	--m-datepicker-background-color: var(--cheng);
	--m-datepicker-border-color: var(--changchun);
	--m-datepicker-hover-color: var(--coari);
	--dp-input-padding: 13px 16px;
	--dp-menu-background: var(--cheng);
}
.tao:root {
	--m-datepicker-hover-color: var(--gray-600);
	--dp-menu-background: var(--gray-800);
	--dp-border-radius: 4px;
	--dp-input-padding: 10px;
	--dp-font-size: 16px;
	--m-datepicker-input-color: var(--a-input-color);
	--m-datepicker-border-color: transparent;
	--m-datepicker-button-background-color: transparent;
}
</style>
<style scoped lang="scss">
.dp__main {
	max-width: var(--m-datepicker-max-width);
}
:deep() {
	.dp__input {
		background-color: var(--m-datepicker-background-color);
		line-height: 21px;
		font-weight: 700;
		color: var(--m-datepicker-input-color);
		border-color: var(--m-datepicker-border-color);

		&::placeholder {
			opacity: 1;
			font-weight: 400;
			color: var(--m-datepicker-input-color);
		}
	}
	.dp__input_icon {
		left: auto;
		right: 10px;
		color: var(--m-datepicker-icon-color);
	}
	.dp__arrow_top,
	.dp__arrow_bottom,
	.dp__calendar_header_separator,
	.dp__button.dp__overlay_action {
		display: none;
	}
	.dp__action_row {
		width: 100% !important;
		padding: 8px 0 24px;
	}
	.dp__instance_calendar {
		padding-bottom: 8px;
	}
	.dp__action_buttons {
		flex: 1;
		justify-content: center;
		gap: 24px;
	}
	.dp__action_button {
		border-radius: 50px;
		margin-left: 0;
		height: 40px;
		padding: 8px 30px;
	}
	.dp__action_select {
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		box-shadow: 0 2px 10px var(--campeche);
		background-color: var(--m-datepicker-button-background-color);
	}
	.dp__action_cancel {
		color: var(--m-datepicker-header-color);
		border: none;
	}
	.dp__menu {
		background: var(--dp-menu-background);
		border: none;
		font-size: 14px;
		box-shadow: 0 14px 20px rgba(0, 0, 0, 0.15);
		border-radius: 6px;
	}
	.dp__month_year_wrap {
		order: -1;
	}
	.dp__month_year_col_nav:last-of-type {
		margin-left: 12px;
	}
	.dp__inner_nav {
		--dp-disabled-color: var(--m-datepicker-disabled-color);

		color: #fff;
		&:hover {
			background-color: var(--m-datepicker-hover-color);
		}
	}
	.dp__month_year_select {
		color: #fff;
		width: auto;
		font-size: 18px;
		&:hover {
			background-color: var(--m-datepicker-hover-color);
		}
	}
	.dp__calendar_header {
		color: var(--m-datepicker-header-color);
		margin-top: 16px;
		font-weight: 400;
	}
	.dp__calendar_header_item {
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.dp__calendar_item {
		color: #fff;
	}
	.dp__active_date {
		background-color: var(--m-datepicker-button-background-color);
	}
	.dp__range_end,
	.dp__range_start {
		background-color: var(--m-datepicker-hover-color);
		position: relative;
		&::before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			background-color: var(--m-datepicker-button-background-color);
			border-radius: var(--dp-cell-border-radius);
		}
	}
	.dp__range_between {
		background-color: var(--m-datepicker-hover-color);
		border: none;
	}
	.dp__date_hover_end:hover,
	.dp__date_hover_start:hover,
	.dp__date_hover:hover,
	.dp__overlay_cell:hover {
		background-color: var(--m-datepicker-hover-color);
		color: #fff;
	}
	.dp__overlay {
		background: var(--dp-menu-background);
		border-radius: 6px;
		color: #fff;
		overflow: hidden;
		&_container::-webkit-scrollbar-track {
			background-color: var(--dp-menu-background);
		}
	}
	.dp__overlay_cell_active {
		border-radius: 100px;
		background-color: var(--m-datepicker-button-background-color);
	}
	.dp__overlay_cell {
		border-radius: 100px;
	}
	.dp__overlay_row {
		max-width: 280px;
	}
	.dp__container_block {
		margin-top: 24px;
	}
	.dp__cell_disabled {
		--dp-secondary-color: var(--m-datepicker-disabled-color);
	}
}
</style>
